// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

:root {
	--dangerdarker: rgb(182, 29, 24);
}

.img-datatable {
	max-width: 4rem;
    margin: auto;
    display: block;
}

.modal-dialog {
	&.modal-full {
		max-width: 100%;
		.modal-content {
			.modal-header, .modal-body, .modal-footer {
				max-width: 100% !important;
			}
		}
	}
	&.fixed-header-footer {
		margin: 0 auto;
		.modal-content {
			border: none;
			border-radius: 0;
			.modal-header,.modal-footer {
			    position: fixed;
			    margin: 0 auto;
			    width: 100%;
			    height: 4rem;
			    z-index: 3;
			    background: white;
				@media (min-width: 576px) {
					max-width: 500px;
				}
			}
			.modal-header {
			    top: 0;
			}
			.modal-footer {
				bottom: 0;
			}
		 	.modal-body {
			    padding-top: 4.5rem;
			    padding-bottom: 4.5rem;
	    		min-height: 100vh;
			}
		}
		@media (min-width: 992px) {
			&.modal-lg, &.modal-xl {
				.modal-content {
					.modal-header,.modal-footer {
			    		max-width: 800px;
					}
				}
			}
		}
		@media (min-width: 1200px) {
			&.modal-xl {
				.modal-content {
					.modal-header,.modal-footer {
			    		max-width: 1140px;
					}
				}
			}
		}
	}
}